<template>
  <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary">
    <!-- button Vincular hijo -->
    <div class="mb-1 d-flex justify-content-end">
      <b-button variant="primary" @click="modalRestrictProducts" class="mr-1">
        <feather-icon icon="PlusIcon" class="mr-50" />
        Restricciones
      </b-button>

      <b-button variant="primary" @click="showModal = true">
        <feather-icon icon="PlusIcon" class="mr-50" />
        Vincular hijo
      </b-button>
    </div>

    <!-- data de los hijos -->
    <b-row v-if="childsData && childsData.length > 0">
      <b-col v-for="child in childsData" :key="child.id" cols="12" md="6" class="d-flex">
        <b-card>
          <b-button class="absolute-top-right" variant="outline-danger" size="sm" @click="deleteChild(child)"
            style="z-index: 1">
            <feather-icon icon="TrashIcon" />
          </b-button>

          <b-row>
            <!-- User Info: Left col -->
            <b-col cols="12" class="d-flex justify-content-between flex-column">
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start mb-2">
                <b-avatar :src="imagePreviews[child.id] || child.avatar"
                  :text="child.avatar ? child.avatar : avatarText(child.name)" :variant="`light-primary`" size="104px"
                  rounded />

                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0 cursor-pointer children-name">
                      <a :href="`/profile/${child.id}/user`">
                        {{ child.name + " " + child.last_name }}
                      </a>
                    </h4>
                    <span class="card-text">{{ child.email }}</span>
                    <div v-if="child.avatar">
                      <a :href="apiUrl(child.id)" class="btn btn-outline-warning btn-sm">Descargar Carnet</a>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap">
                    <b-button variant="primary" size="sm" @click="handleButtonClick(child.id)">
                      <input :ref="'inputFile' + child.id" type="file" class="d-none"
                        @change="handleFileChange($event, child.id)" />

                      <feather-icon icon="UploadIcon" class="d-inline mr-sm-50" />
                      <span class="d-none d-sm-inline">Subir Foto</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col cols="12" xl="6" class="mb-1">
              <table class="w-100">
                <tr v-if="child.age">
                  <th class="pb-50">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">Edad</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ `${child.age} años` }}
                  </td>
                </tr>
                <tr v-if="child.status">
                  <th class="pb-50">
                    <feather-icon icon="CheckIcon" class="mr-75" />
                    <span class="font-weight-bold">Estatus</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ child.status ? "Activo" : "Inactivo" }}
                  </td>
                </tr>
                <tr v-if="child.roles">
                  <th class="pb-50">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">Rol</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ child.roles[0].label }}
                  </td>
                </tr>
                <tr v-if="child.grado">
                  <th class="pb-50">
                    <feather-icon icon="FlagIcon" class="mr-75" />
                    <span class="font-weight-bold">Grado</span>
                  </th>
                  <td class="pb-50">
                    {{ child.grado }}
                  </td>
                </tr>
                <tr v-if="child.aula">
                  <th>
                    <feather-icon icon="HashIcon" class="mr-75" />
                    <span class="font-weight-bold">Aula</span>
                  </th>
                  <td>
                    {{ child.aula }}
                  </td>
                </tr>
                <tr v-if="child.celular">
                  <th>
                    <feather-icon icon="PhoneIcon" class="mr-75" />
                    <span class="font-weight-bold">Celular:</span>
                  </th>
                  <td>
                    {{ child.celular }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">Colegio:</span>
                  </th>
                  <td>
                    {{ child.school ? child.school.name : "" }}
                  </td>
                </tr>
              </table>
            </b-col>

            <b-col v-if="child.wallet != null" cols="12" xl="6">
              <ul class="list-unstyled">
                <li>
                  <span class="align-middle text-nowrap">Saldo:
                    <span :class="child.wallet.balance < 0
                      ? 'text-danger'
                      : 'text-success'
                      ">
                      {{
                        child.wallet.balance
                        | currency({ symbol: "$", precision: 2 })
                      }}
                    </span>
                  </span>
                </li>
                <li>
                  <span class="align-middle text-nowrap">Saldo Pendiente:
                    <span class="text-warning">
                      {{
                        child.wallet.pending_balance
                        | currency({ symbol: "$", precision: 2 })
                      }}
                    </span>
                  </span>
                </li>
                <li>
                  <span class="align-middle text-nowrap">Tipo de Billetera:
                    <span class="text-warning">
                      {{ child.wallet.type }}
                    </span>
                  </span>
                </li>
                <li v-if="child.wallet.type == 'Pospago'">
                  <span class="align-middle">Válido hasta:
                    <strong>{{ child.wallet.expiry_date }}</strong></span>
                </li>
                <li v-if="child.wallet.type == 'Pospago' && child.wallet.max_limit
                ">
                  <span class="align-middle text-nowrap">Credito:
                    <span class="text-warning">
                      {{
                        child.wallet.max_limit
                        | currency({ symbol: "$", precision: 2 })
                      }}
                    </span>
                  </span>
                </li>
              </ul>

              <div class="d-flex justify-content-center align-items-center mb-1">
                <vue-qr
                  :text="child.wallet.public_key"
                  :size="130"
                  :margin="0"
                  :logoCornerRadius="10"
                  :bindElement="true"
                ></vue-qr>
              </div>

              <div v-if="isCashier || isAdmin" class="d-flex justify-content-center align-items-center mt-2">
                <b-button variant="success" size="sm" @click="messageWhatsApp(child)">
                  Cobrar whatsApp
                </b-button>
              </div>

              <div class="d-flex justify-content-between align-items-center mt-2">
                <b-button :to="`/transactions?child=${child.id}`" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary" size="sm">
                  Transacciones
                </b-button>

                <RechargeMode :wallet="child.wallet"></RechargeMode>
              </div>
            </b-col>

            <b-col cols="12">
              <hr />
            </b-col>

            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-start">
                <div>
                  <b-card-title>Nota:</b-card-title>
                </div>
                <div>
                  <b-button :variant="child.observation ? 'outline-warning' : 'outline-primary'
                    " @click="addNoteChildModelOpen(child)" size="sm">
                    <feather-icon :icon="child.observation ? 'EditIcon' : 'PlusIcon'" />
                  </b-button>
                </div>
              </div>

              <b-row>
                <b-col cols="12">
                  {{ child.observation || "No hay notas" }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col cols="12">
        <b-alert show variant="warning" class="p-2">
          No hay hijos vinculados.
        </b-alert>
      </b-col>
    </b-row>

    <!-- data de los hijos -->
    <b-modal v-model="showModalRestrictProducts" centered :title="'Lista de Productos Restringidos'" hide-footer
      no-close-on-backdrop size="xl">
      <b-overlay :show="loading" rounded responsive variant="transparent" opacity="0.33" blur="2px">
        <div>
          <div class="row">
            <div class="col-12">
              <h4>Seleccione un hijo</h4>
              <div class="d-flex flex-row">
                <div v-for="child in childsData" :key="child.id" class="mr-4">
                  <b-form-group>
                    <b-form-checkbox v-model="selectedChildId" :value="child.id" :name="child.name"
                      :unchecked-value="false" @change="handleCheckboxChange($event, child.id)">
                      {{ child.name + " " + child.last_name }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>

              <b-form-group label="Buscar Producto:">
                <b-overlay :show="loadingProduct" rounded responsive variant="transparent" opacity="0.33" blur="2px">
                  <b-form-input v-model="searchProductData.product" @input="searchProduct"
                    placeholder="Buscar producto" />
                </b-overlay>
              </b-form-group>

              <!-- Tabla de productos Nuevos -->
              <div v-if="isMobileView">
                <!-- Vista móvil con tarjetas -->
                <div v-if="productsNew.length > 0">
                  <h4>Productos Disponibles</h4>
                  <div v-for="product in productsNew" :key="product.id" class="mb-3">
                    <b-card>
                      <img :src="product.image" alt="Product Image" class="img-fluid mb-1" top />
                      <b-card-body>
                        <b-card-title>{{ product.name }}</b-card-title>
                        <b-card-text>
                          {{ product.description }}
                          <div>
                            Precio:
                            {{
                              product.price
                              | currency({ symbol: "$", precision: 2 })
                            }}
                          </div>
                        </b-card-text>
                        <b-button variant="success" @click.prevent="addProduct(product)">
                          <feather-icon icon="PlusIcon" class="mr-50" />
                          Agregar
                        </b-button>
                      </b-card-body>
                    </b-card>
                  </div>
                </div>
                <div v-else>
                  <b-alert show variant="warning" class="p-2">
                    No hay productos disponibles.
                  </b-alert>
                </div>
              </div>

              <div v-else>
                <div v-if="productsNew.length > 0">
                  <h4>Productos Disponibles</h4>
                  <b-row>
                    <b-col v-for="product in productsNew" :key="product.id" sm="12" md="6" lg="3"
                      class="d-flex justify-content-center mt-2">
                      <b-card class="ecommerce-card">
                        <b-row>
                          <b-col class="text-center">
                            <div class="text-truncate">
                              <h4 class="mb-1">{{ product.name }}</h4>
                            </div>
                            <b-col>
                              <img :src="product.image" alt="Product Image" width="150" height="150" class="mb-1" />
                            </b-col>
                            <b-col class="text-truncate">
                              Descripción: {{ product.description }}
                            </b-col>
                            <b-col class="text-truncate">
                              Precio:
                              {{
                                product.price
                              | currency({ symbol: "$", precision: 2 })
                              }}
                            </b-col>
                            <b-col class="text-truncate">
                              Bs:
                              {{
                                ($store.state.bank.data.amount * product.price)
                                | currency({ symbol: "VEF", precision: 2 })
                              }}
                            </b-col>
                            <b-button class="mt-1" variant="success" @click.prevent="addProduct(product)" block>
                              <feather-icon icon="PlusIcon" class="mr-50" />
                              Agregar
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>

                <b-alert v-else show variant="warning" class="p-2">
                  No hay productos disponibles.
                </b-alert>
              </div>

              <!-- Tabla de productos -->
              <div v-if="products.length > 0">
                <h4>Productos Restringidos</h4>
                <b-row>
                  <b-col v-for="product in products" :key="product.id" sm="12" md="6" lg="3"
                    class="d-flex justify-content-center mt-2">
                    <b-card class="ecommerce-card">
                      <b-row>
                        <b-col class="text-center">
                          <div class="text-truncate">
                            <h4 class="mb-1">{{ product.name }}</h4>
                          </div>
                          <b-col>
                            <img :src="product.image" alt="Product Image" width="150" height="150" class="mb-1" />
                          </b-col>
                          <b-col class="text-truncate">
                            Descripción: {{ product.description }}
                          </b-col>
                          <b-col class="text-truncate">
                            Precio:
                            {{
                              product.price
                              | currency({ symbol: "$", precision: 2 })
                            }}
                          </b-col>
                          <b-col class="text-truncate">
                            Bs:
                            {{
                              ($store.state.bank.data.amount * product.price)
                              | currency({ symbol: "VEF", precision: 2 })
                            }}
                          </b-col>
                          <b-button class="mt-1" variant="danger" @click.prevent="deleteProduct(product)" block>
                            Borrar
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-modal>
    <CreateStudent :typeReadOnly="true" :showModal="showCreateModal" :schoolId="userData.school_id" :fromParent="true"
      typeUser="student" @closeModal="closeModal" @autoSelect="autoSelect" />
    <b-modal v-model="showModal" centered :title="'Vinculación de hijos'" hide-footer no-close-on-backdrop>
      <b-overlay :show="loading" rounded responsive variant="transparent" opacity="0.33" blur="2px">
        <validation-observer ref="rechargeForm">
          <div class="row">
            <div class="col-12">
              <v-select multiple :loading="loadingUsers" v-model="childsAdd" @search="searchUsers" :search="searchTerm"
                ref="userSelect" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="allUsers" label="name"
                placeholder="Buscar por correo electrónico" :reduce="(item) => item" :filterable="false"
                :close-on-select="true">
                <template v-slot:option="option">
                  {{ option.name }} {{ option.last_name }} -
                  {{ option.email }} - {{ option.school.name }}
                </template>

                <template v-slot:selected-option="option">
                  {{ option.name }} {{ option.last_name }} -
                  {{ option.email }} - {{ option.school.name }}
                </template>

                <template v-slot:no-options>
                  <b-alert show variant="warning" class="p-1 mt-2">
                    No hay usuarios disponibles.
                  </b-alert>

                  <b-button variant="primary ml-2" @click="openCreateModal">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    Crear
                  </b-button>
                </template>
              </v-select>
            </div>
          </div>

          <footer class="modal-footer p-0">
            <b-button variant="primary" @click="saveMergeChilds()">
              <feather-icon icon="PlusIcon" class="mr-50" />
              Agregar
            </b-button>


          </footer>
        </validation-observer>
      </b-overlay>
    </b-modal>

    <b-modal v-model="addNoteChildModel" centered :title="'Agregar Nota'" hide-footer no-close-on-backdrop>
      <b-overlay :show="loading" rounded responsive variant="transparent" opacity="0.33" blur="2px">
        <div>
          <div class="row">
            <div class="col-12">
              <b-form @submit.prevent="addNoteChildMethod">
                <b-form-group label="Nota:">
                  <b-form-group>
                    <b-form-input v-model="addNoteChild" />
                  </b-form-group>
                  <b-button type="submit" variant="primary"> Guardar </b-button>
                </b-form-group>
              </b-form>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BOverlay,
  VBModal,
  BFormInput,
  BFormGroup,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BTable,
  BCardBody,
  BAlert,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
localize("es", es);
import DatePicker from "vue2-datepicker";
import RechargeMode from "./RechargeMode";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";
import vSelect from "vue-select";
import CreateStudent from "../CreateStudent/CreateStudent.vue";
import UserViewUserWalletInfo from "./UserViewUserWalletInfo.vue";
import VueQr from "vue-qr";

export default {
  components: {
    vSelect,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BOverlay,
    VBModal,
    CreateStudent,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    DatePicker,
    BCardTitle,
    BForm,
    BFormCheckbox,
    BTable,
    RechargeMode,
    UserViewUserWalletInfo,
    BCardBody,
    BAlert,
    VueQr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    childsData: {
      type: Array,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "image", label: "Imagen" },
        { key: "name", label: "Nombre" },
        { key: "description", label: "Descripción" },
        { key: "price", label: "Precio" },
        { key: "convertedPrice", label: "BS" },
        { key: "actions", label: "Acciones" },
      ],
      imagePreview: null,
      imagePreviews: {},
      searchTerm: '',
      selectedChildId: null,
      observation: "",
      copyItemText: "",
      showBank: false,
      comeFromModal: false,
      showCreateModal: false,
      dataBanks: [],
      disabled: true,
      required,
      showModal: false,
      showModalRestrictProducts: false,
      showModalAddObservation: false,
      showModalCheck: false,
      selectedProducts: [],
      childSelected: [],
      products: [],
      searchProductData: {
        product: null,
      },
      formData: {
        name: null,
        lastname: null,
        birthday: null,
      },
      childsNew: [],
      loading: false,
      loadingProduct: false,
      modeEdit: false,
      childs: [],
      childsAdd: [],
      productsNew: [],
      selectedUser: [],
      loadingUsers: false,
      allUsers: [],
      searchTimeout: null,
      isMobileView: false,
      addNoteChildModel: false,
      isEditObservation: false,
      addNoteChild: "",
      ChildId: "",
    };
  },
  filters: {
    avatarText,
  },
  setup() {
    return {
      avatarText,
    };
  },
  mounted() { },
  computed: {
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    isParent() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "representative"
      );
    },
  },
  methods: {
    apiUrl(idUser) {
      return process.env.VUE_APP_API_URL + "api/download/wallet/" + idUser;
    },
    openCreateModal() {
      this.showCreateModal = false;
      setTimeout(() => {
        this.showCreateModal = true;

      }, 100);

    },
    closeModal() {
      this.showCreateModal = false;
    },
    autoSelect(data) {
      // Asegúrate de que el v-select esté montado
      if (this.$refs.userSelect) {
        // Acceder al input de búsqueda del v-select y rellenarlo con el correo electrónico
        this.comeFromModal = true;
        this.$refs.userSelect.search = data.user.email;
      }
      this.closeModal()
    },
    async addNoteChildMethod() {
      this.loading = true;
      await this.$store
        .dispatch("user/addNoteChild", {
          childId: this.ChildId,
          data: { note: this.addNoteChild },
        })
        .then(async (res) => {
          this.loading = false;
          this.addNoteChildModel = false;
          this.addNoteChild = "";
          const userData = await this.$store.dispatch(
            "user/showUser",
            this.$route.params.id
          );
          this.message(
            "Nota",
            "checkCircleIcon",
            "success",
            "Agregada con exito"
          );
        })
        .catch(() => {
          this.message(
            "Nota",
            "AlertCircleIcon",
            "danger",
            "Falló en la carga"
          );
          this.loading = false;
        });
    },
    addNoteChildModelOpen(child) {
      this.ChildId = child.id;
      this.addNoteChildModel = true;
      this.addNoteChild = child.observation;
    },
    async deleteChild(childId) {
      try {
        const res = await this.$bvModal.msgBoxConfirm(
          `Estas seguro de desvincular el hijo ${childId.name} ${childId.last_name}?`,
          {
            title: "Confirmar",
            size: "sm",
            okVariant: "primary",
            okTitle: "Si",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (res) {
          await this.$store
            .dispatch("user/deleteChild", {
              childId: childId.id,
              data: {
                userId: this.$route.params.id,
              },
            })
            .then(async (res) => {
              this.loading = false;
              await this.$store.dispatch(
                "user/showUser",
                this.$route.params.id
              );
            })
            .catch(() => {
              this.message(
                "Busquedad",
                "AlertCircleIcon",
                "danger",
                "Falló en la busqueda"
              );
              this.loading = false;
            });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Hijo desvinculado con exito",
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "No se pudo desvincular el hijo",
          },
        });
      }
    },
    async searchUsers(search) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        if (search && search.length > 2) {
          this.loadingUsers = true;
          await this.$store
            .dispatch("user/searchUsers", {
              search: search,
              isVinculedChild: true,
              schoolId: this.userData.school_id,
            })
            .then((res) => {
              this.loadingUsers = false;
              this.allUsers = res;
              if (this.comeFromModal) {
                this.childsAdd.push(res[0])
                this.$refs.userSelect.search = '';
              }
            })
            .catch(() => {
              this.message(
                "Busquedad",
                "AlertCircleIcon",
                "danger",
                "Falló en la busqueda"
              );
              this.loadingUsers = false;
            });
        }
      }, 500); // 500ms de retraso
    },

    handleButtonClick(childId) {
      let inputElementArray = this.$refs["inputFile" + childId];

      if (inputElementArray && inputElementArray.length > 0) {
        let actualInputElement = inputElementArray[0];
        if (typeof actualInputElement.click === "function") {
          actualInputElement.click();
        } else {
          console.error("El elemento no tiene un método click");
        }
      } else {
        console.error("El elemento no se encontró");
      }
    },
    handleFileChange(event, userId) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (userId) {
            // Caso para los hijos
            this.$set(this.imagePreviews, userId, e.target.result);
            this.uploadImage(e.target.result, userId);
          } else {
            // Caso para la imagen principal
            this.imagePreview = e.target.result;
            this.uploadImage(e.target.result, this.userData.id);
          }
        };
        reader.readAsDataURL(file);
      }
    },

    async uploadImage(base64Image, userId) {
      this.loading = true;
      await this.$store
        .dispatch("user/uploadAvatar", {
          userId: userId,
          data: { avatar: base64Image },
        })
        .then(() => {
          this.message(
            "Subida de imagen",
            "checkCircleIcon",
            "success",
            "Cargada con exito"
          );
          this.loading = false;
        })
        .catch((res) => {
          this.imagePreview = this.userData.avatar;
          this.imagePreviews = this.childsData.childs.map((child) => {
            return { [child.id]: child.avatar };
          });
          this.message(
            "Subida de imagen",
            "AlertCircleIcon",
            "danger",
            res.error || "Falló en la carga"
          );
          this.loading = false;
        });
    },
    modalRestrictProducts() {
      if (this.childsData.length === 0) {
        this.message(
          "Restricción de productos",
          "AlertCircleIcon",
          "danger",
          "Debe tener al menos un hijo"
        );
        return;
      }
      this.showModalRestrictProducts = true;
    },
    async addProduct(product) {
      const productExists = this.products.some((p) => p.id === product.id);

      if (!productExists) {
        this.products.unshift(product);
      }

      this.productsNew.splice(this.productsNew.indexOf(product), 1);

      this.loading = true;
      await this.$store
        .dispatch("user/restrictProducts", {
          data: {
            action: "add",
            userIds: this.selectedChildId,
            productIds: [product.id],
          },
        })
        .then((res) => {
          this.loading = false;
          this.searchProductData.product = null;
          // this.showModalRestrictProducts = false;
          // this.productsNew = [];

          this.message(
            "Restricción de productos",
            "checkCircleIcon",
            "success",
            "Restringidos con exito"
          );
        })
        .catch(() => {
          this.message(
            "Restricción de productos",
            "AlertCircleIcon",
            "danger",
            "Falló en la restricción"
          );
          this.loading = false;
        });
    },
    async deleteProduct(product) {
      const index = this.products.findIndex((p) => p.id === product.id);
      if (index !== -1) {
        this.products.splice(index, 1);
      }

      this.loading = true;
      await this.$store
        .dispatch("user/restrictProducts", {
          data: {
            action: "remove",
            userIds: this.selectedChildId,
            productIds: [product.id],
          },
        })
        .then((res) => {
          this.loading = false;
          // this.showModalRestrictProducts = false;
          // this.productsNew = [];

          this.message(
            "Restricción de productos",
            "checkCircleIcon",
            "success",
            "Restringidos con exito"
          );
        })
        .catch(() => {
          this.message(
            "Restricción de productos",
            "AlertCircleIcon",
            "danger",
            "Falló en la restricción"
          );
          this.loading = false;
        });
    },

    async handleCheckboxChange(childSelectedArray, childId) {
      const isChecked = childSelectedArray;

      if (isChecked) {
        // El checkbox ha sido seleccionado
        await this.$store
          .dispatch("user/handleCheckboxChange", {
            childId: childId,
          })
          .then((res) => {
            this.loading = false;
            this.products = res;
          })
          .catch(() => {
            this.message(
              "Busquedad",
              "AlertCircleIcon",
              "danger",
              "Falló en la busqueda"
            );
            this.loading = false;
          });
      } else {
        // El checkbox ha sido deseleccionado
        this.products = [];
      }
    },
    async searchProduct(search) {
      if (!this.selectedChildId) {
        this.message(
          "Restricción de productos",
          "AlertCircleIcon",
          "danger",
          "Debe seleccionar al menos un hijo"
        );
        return;
      }
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        if (search.length > 0) {
          this.loadingProduct = true;
          await this.$store
            .dispatch("user/searchProduct", {
              userIds: this.selectedChildId,
              search: this.searchProductData.product,
            })
            .then((res) => {
              this.productsNew = res;
              this.loadingProduct = false;
            })
            .catch((res) => {
              this.message(
                "Restricción de productos",
                "AlertCircleIcon",
                "danger",
                res.response.data.message
              );
              this.loadingProduct = false;
            });
        } else {
          this.productsNew = [];
        }
      }, 500); // 500ms de retraso
    },
    async saveMergeChilds() {
      this.loadingUsers = true;
      await this.$store
        .dispatch("user/mergeChildsSave", {
          userId: this.$route.params.id,
          data: { childIds: this.childsAdd.map((child) => child.id) },
        })
        .then(async () => {
          await this.$store.dispatch("user/showUser", this.$route.params.id);
          this.loadingUsers = false;
          this.showModal = false;
          this.childsAdd = [];
          this.message(
            "Vinculacion",
            "checkCircleIcon",
            "success",
            "Vinculado con exito"
          );
        })
        .catch(() => {
          this.message(
            "Vinculacion",
            "AlertCircleIcon",
            "danger",
            "Falló en la vinculación"
          );
          this.loadingUsers = false;
        });
    },
    message(message, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: message,
          icon: icon,
          variant: variant,
          text: text,
        },
      });
    },

    messageWhatsApp(user) {
      if (user.phone.length < 10) {
        this.$swal({
          title: `EL usuario no tiene un numero de telefono valido`,
          text: `Número: ${user?.phone}`,
          icon: "error",
        });
        return;
      }

      if (user.wallet == null || user.wallet?.balance >= 0) {
        this.$swal({
          title: `El usuario ${user?.name} ${user?.last_name} no tiene deuda`,
          icon: "success",
        });
        return;
      }

      this.$swal({
        title: `Enviar un mensaje a ${user?.name} ${user?.last_name}?`,
        text: `Número: ${user?.phone}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Enviar",
      }).then(async (result) => {
        if (result.isConfirmed) {

          await this.$store.dispatch("user/sendWhatsAppMessage", user.id);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Mensaje enviado correctamente",
            },
          });
        }
      });
    },
  },
};
</script>

<style>
.productsRow {
  height: 350px;
  width: 100%;
  overflow-y: scroll;
}

.children-name {
  max-width: 170px;
}

.absolute-top-right {
  position: absolute;
  top: 22px;
  right: 22px;
}

.card-text{
  word-break: break-all;

}
</style>
