<template>
  <div>
    <slot name="ButtonAction">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="open()" variant="primary" size="sm">
        Retirar
      </b-button>
    </slot>
    <b-modal v-model="showModal" centered :title="'Retirar Dolares de Billetera'" hide-footer no-close-on-backdrop>
      <b-overlay :show="loading" rounded responsive variant="transparent" opacity="0.33" blur="2px">
        <b-row>
          <b-col cols="12" md="12">
            <div>
              <validation-observer ref="withdrawalForm">
                <b-form-group label="Tipo de Retiro *">
                  <validation-provider #default="{ errors }" name="tipo de pago" rules="required">
                    <v-select 
                      v-model="payments" 
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="paymentTypes" 
                      label="name" 
                      multiple 
                      :reduce="(item) => item" 
                      :return-object="true"
                      @input="typePayments(payments)"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                
                <div v-for="(payment, index) in payments" :key="index">
                  <b-form-group :label="`${'Monto en Dolares: '} ${payment.name}`">
                    <validation-provider #default="{ errors }" name="monto" :min="1" v-model="payment.amountUSD" rules="required">
                      <b-form-input 
                        v-model="payment.amountUSD" 
                        type="number" 
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div v-if="typeBank.length > 0">
                  <h3>Datos bancarios del usuario</h3>
                  <b-row class="mt-2">
                    <b-col v-for="(i, x) in typeBank" :key="x" class="mb-1">
                      <ul class="bank-list">
                        <li>
                          <h4>
                            {{ i.payment }}
                            <feather-icon 
                              icon="CopyIcon" 
                              size="1.1x" 
                              class="custom-class cursor-pointer"
                              @click="copyItemBank(i)" 
                            />
                          </h4>
                        </li>
                        <li>
                          {{ i.account }}
                          <feather-icon 
                            icon="CopyIcon" 
                            class="custom-class cursor-pointer"
                            @click="copyTextItem(i.account)" 
                          />
                        </li>
                        <li>
                          {{ i.name }}
                          <feather-icon 
                            icon="CopyIcon" 
                            class="custom-class cursor-pointer"
                            @click="copyTextItem(i.name)" 
                          />
                        </li>
                        <li>
                          {{ i.id_card }}
                          <feather-icon 
                            icon="CopyIcon" 
                            class="custom-class cursor-pointer"
                            @click="copyTextItem(i.id_card)" 
                          />
                        </li>
                        <li v-if="i.phone">
                          {{ i.phone }}
                          <feather-icon 
                            icon="CopyIcon" 
                            class="custom-class cursor-pointer"
                            @click="copyTextItem(i.phone)" 
                          />
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                </div>

                <footer class="modal-footer p-0">
                  <div class="mt-1">
                    <b-button variant="outline-primary modal-btn mr-1" @click="close">
                      Cancelar
                    </b-button>
                    <b-button variant="primary" :disabled="loading" @click="save">
                      {{ loading ? "Procesando..." : "Guardar" }}
                    </b-button>
                  </div>
                </footer>
              </validation-observer>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BOverlay,
  BMedia,
  BRow,
  BCol,
  BCard,
  BTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";

localize("es", es);

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BRow,
    BCol,
    BCard,
    BTooltip,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    wallet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      disabled: true,
      showBank: false,
      paymentTypes: [],
      dataBanks: [],
      typeBank: [],
      payments: [],
      showModal: false,
      loading: false,
    };
  },
  mounted() {
    this.getBanks();
  },
  methods: {
    copyTextItem(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Copiado al portapapeles",
              icon: "CheckCircleIcon",
              variant: "success",
              text: `Copiado: ${text}`,
            },
          });
        })
        .catch((err) => {
          console.error("No se pudo copiar el texto: ", err);
        });
    },

    copyItemBank(i) {
      const text = `${i.payment}:\n${i.account}\n${i.name}\n${i.id_card}\n${i.phone === null ? "" : i.phone}`;
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Copiado al portapapeles",
              icon: "CheckCircleIcon",
              variant: "success",
              text: `Copiado datos de: ${i.payment}`,
            },
          });
        })
        .catch((err) => {
          console.error("No se pudo copiar el texto: ", err);
        });
    },

    async typePayments(payments) {
      this.typeBank = [];
      for (const payment of payments) {
        const filteredBanks = await this.filterBanksType(
          this.dataBanks?.banks,
        //   payment.code.toLowerCase()
        );
        this.typeBank = [...this.typeBank, ...filteredBanks];
      }
      this.typeBank = [
        ...new Set(this.typeBank.map((bank) => JSON.stringify(bank))),
      ].map((bank) => JSON.parse(bank));
    },

    async filterBanksType(banks, paymentType) {
      return banks
        .filter(
          (bank) =>
            (paymentType === "pago_movil" &&
              bank.type.toLowerCase().includes("pago móvil")) ||
            (paymentType === "transferencia" &&
              bank.type.toLowerCase().includes("corriente"))
        )
        .map((bank) => ({
          ...bank,
          payment:
            paymentType === "pago_movil" ? "Pago Móvil" : "Transferencia",
        }));
    },

    async getBanks() {
      this.dataBanks = await this.$store.dispatch("bank/index");
    },

    async open() {
      this.paymentTypes = await this.$store.dispatch("products/getPayments", {
        payment: "RechargeMode",
      });
      this.showModal = true;
    },

    async save() {
      try {
        if (!(await this.$refs.withdrawalForm.validate())) {
          return;
        }

        for (const payment of this.payments) {
          if (payment.amountUSD < 1) {
            this.showToastError("El monto debe ser mayor a 1 dólar");
            return;
          }

          if (payment.amountUSD > this.wallet.balance) {
            this.showToastError("Saldo insuficiente para realizar el retiro");
            return;
          }

          if (payment.amountUSD <= 0) {
            this.showToastError(`El monto debe ser mayor a 0 para ${payment.name}`);
            return;
          }
        }

        this.loading = true;

        const withdrawalData = this.payments.map(payment => ({
          paymentMethod: payment.code,
          amount: parseFloat(payment.amountUSD),
          balance: this.wallet.balance
        }));

        const response = await this.$store.dispatch("user/withdrawalMode", {
          walletId: this.wallet.id,
          data: withdrawalData
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Éxito",
            icon: "CheckCircleIcon",
            variant: "success",
            text: response.data.message
          }
        });

        await this.$store.dispatch("user/showUser", this.$route.params.id);
        
        this.close();

      } catch (error) {
        let errorMessage = "Ocurrió un error inesperado. Intente nuevamente.";

        if (error.code === 'ECONNABORTED') {
          errorMessage = "La solicitud tardó demasiado tiempo. Inténtalo de nuevo.";
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        }

        this.showToastError(errorMessage);
      } finally {
        this.loading = false;
      }
    },

    showToastError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "XCircleIcon",
          variant: "danger",
          text: message
        }
      });
    },

    close() {
      this.showModal = false;
      this.$refs.withdrawalForm.reset();
      this.payments = [];
      this.paymentTypes = [];
      this.typeBank = [];
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.bank-list {
  list-style-type: none;
  padding-left: 0;
}

.bank-list li {
  margin-bottom: 10px;
}

.bank-list li .b-button {
  margin-left: 10px;
}
</style>