<template>
  <b-card no-body class="border-primary">
    <b-card-header
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <h5 class="mb-0"></h5>
      <b-badge variant="light-primary">
        {{ userData.wallet.type }}
      </b-badge>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled">
        <li>
          <span class="align-middle text-nowrap"
            >Saldo:
            <span
              :class="
                userData.wallet.balance < 0 ? 'text-danger' : 'text-success'
              "
            >
              {{
                userData.wallet.balance
                  | currency({ symbol: "$", precision: 2 })
              }}
            </span>
          </span>
        </li>
        <li class="my-25">
          <span class="align-middle text-nowrap"
            >Saldo Pendiente:
            <span class="text-warning">
              {{
                userData.wallet.pending_balance
                  | currency({ symbol: "$", precision: 2 })
              }}
            </span>
          </span>
        </li>
        <li v-if="userData.wallet.type == 'Pospago'">
          <span class="align-middle"
            >Válido hasta:
            <strong>{{ userData.wallet.expiry_date }}</strong></span
          >
        </li>
        <li
          v-if="userData.wallet.type == 'Pospago' && userData.wallet.max_limit"
        >
          <span class="align-middle text-nowrap"
            >Credito:
            <span class="text-warning">
              {{
                userData.wallet.max_limit
                  | currency({ symbol: "$", precision: 2 })
              }}
            </span>
          </span>
        </li>

        <li v-if="isCashier || isAdmin" class="mb-1">
          <b-button
            variant="success"
            size="sm"
            @click="messageWhatsApp(userData)"
          >
            Cobrar whatsApp
          </b-button>
        </li>

        <li v-if="isCashier || isAdmin" class="mt-1 mb-1">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="copyItem(userData.wallet_currency_id)"
          >
            Copiar Identificador de Saldo
          </b-button>
        </li>
      </ul>
      <div class="d-flex justify-content-center align-items-center mb-1">
        <vue-qr
          :text="userData.wallet.public_key"
          :size="130"
          :margin="0"
          :logoCornerRadius="10"
          :bindElement="true"
        ></vue-qr>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <b-button
          :to="route"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
        >
          Transacciones
        </b-button>

        <RechargeMode :wallet="userData.wallet"></RechargeMode>
        
        <RetiroMode v-if="isAdmin" :wallet="userData.wallet"></RetiroMode>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BBadge, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VueQr from "vue-qr";
import { $themeConfig } from "@themeConfig";
import RechargeMode from "./RechargeMode";
import RetiroMode from "./RetiroMode";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    route() {
      const isAdmin = this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
      const isCashier = this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
      if (isAdmin || isCashier) {
        return {
          name: "transactions",
          query: {
            userId: this.userData.id,
          },
        };
      } else {
        return {
          name: "transactions",
        };
      }
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
  },
  methods: {
    copyItem(balanceId) {
      navigator.clipboard.writeText(balanceId);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "¡Éxito!",
          icon: "CheckCircleIcon",
          variant: "success",
          text: "ID Copiado al portapapeles, comparta este numero con soporte para revisar este saldo",
        },
      });
    },

    messageWhatsApp(user) {
      if (user.phone.length < 10) {
        this.$swal({
          title: `EL usuario no tiene un numero de telefono valido`,
          text: `Número: ${user?.phone}`,
          icon: "error",
        });
        return;
      }

      if (user.wallet == null || user.wallet?.balance >= 0) {
        this.$swal({
          title: `El usuario ${user?.name} ${user?.last_name} no tiene deuda`,
          icon: "success",
        });
        return;
      }

      this.$swal({
        title: `Enviar un mensaje a ${user?.name} ${user?.last_name}?`,
        text: `Número: ${user?.phone}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Enviar",
      }).then(async (result) => {
        if (result.isConfirmed) {

          await this.$store.dispatch("user/sendWhatsAppMessage", user.id);



          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Mensaje enviado correctamente",
            },
          });
        }
      });
    },
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    RechargeMode,
    RetiroMode,
    VueQr,
  },
  setup(props) {
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      appLogoImage,
    };
  },
};
</script>

<style></style>
